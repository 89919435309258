<template>
    <div>
        <h1>Shop Tasks</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './job_opt.vue'
    import GroupBy from './utils/GroupBy.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";

    export default {
        name: "job",
        components: {
            Options,
            GroupBy,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: `${new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString()} 00:00`, qsField: '[dateandtimes][start_time_from]'},
                    endDate: {val: `${new Date().toLocaleDateString()} 00:00`, qsField: '[dateandtimes][start_time_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][sh.cdi_location_id]'},
                    shift: {val: 0, qsField: '[numbers][s.user_staff_shift_id]'},
                    employeeId: {val: 0, qsField: '[numbers][tt.user_id]'},
                    currentJob: {val: null, qsField: '[current_only]'},
                    lineMaster: {val: null, qsField: '[isset][ti.line_master]'},
                    showTasks: {val: [0], qsField: '[task_ids]', defaultVal: [0]},
                },
                groupByFields: {
                    rangemonth : {label: 'Month'},
                    employee : {label: 'Employee'},
                    task : {label: 'Task'},
                    cdi_location : {label: 'Location'}
                },
                dataFields: {
                    employee : {label: 'Employee', checked: true},
                    tracker_id : {label: 'Order', checked: true},
                    nomenclature : {label: 'Item', checked: true},
                    price: {label: 'Price', checked: false},
                    qty : {label: 'Qty', checked: false},
                    task : {label: 'Task', checked: true},
                    taskdate : {label: 'Date', checked: false},
                    t_start : {label: 'Start', checked: true},
                    t_end : {label: 'Stop', checked: false},
                    duration: {label: 'Mins', checked: true}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Shop Tasks']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>