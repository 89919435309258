<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{class: 'form-control'}" :pickTime="true" :format="'MM/DD/YYYY hh:mm'" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{class: 'form-control'}" :pickTime="true" :format="'MM/DD/YYYY hh:mm'" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shifts</label>
                <select class="form-control" v-model="state.optionFields.shift.val">
                    <option value="0">All</option>
                    <option v-for="[id, shift] in cache.shiftCache.SHIFTS" v-bind:key="id" :value="id">{{ shift }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Employee</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.employeeId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.currentJob.val"> Current Job</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.lineMaster.val"> Line Master</label>
            </div>

            <div class="col-xs-12" v-show="!toggleHide"><div class="filter_separator">Show Tasks</div></div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide" >
                <label><input type=checkbox v-model="state.optionFields.showTasks.val" value="0"> All</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide"  v-for="[id, task] in cache.taskCache.SHOP_TASKS" v-bind:key="id">
                <label><input type=checkbox :true-value="1"  v-model="state.optionFields.showTasks.val" :false-value=null :value="id"> {{ task }}</label>
            </div>

            <div class="col-xs-12">
                <button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">{{ (toggleHide) ? 'More Filters' : 'Less Filters'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import taskCache from '@/cache/task.cache.js';
    import shiftCache from '@/cache/shift.cache.js';
    import DatePicker from "../utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    export default {
        name: "Options",
        components: {DatePicker, Typeahead },
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    taskCache,
                    shiftCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            },
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>